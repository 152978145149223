import * as React from "react";
import BigHead from "../layouts/BigHead";
import { BannerOption } from "../components/ActionButtons";

const ContactPage = () => {
  const title = "Contact";
  const description = "Contact Music Glue customer support";

  return (
    <BigHead title={title} description={description} hideTitle>
      <section className="relative grow">
        <div className="md:grid md:grid-cols-3 gap-8 mb-12 pt-12 mx-auto">
          <BannerOption
            link="/customer-help"
            title="Customer Help"
            description="Contact Music Glue for help with your order"
          />
          <BannerOption
            link="/business-enquiries"
            title="Business Enquiries"
            secondaryStyle
            description="Contact business enquiries if you would like to work with Music Glue"
          />
          <BannerOption
            link="/seller-support"
            title="Seller Support"
            secondaryStyle
            description="Contact seller support if you sell on Music Glue and need assistance"
          />
        </div>
      </section>
    </BigHead>
  );
};

export default ContactPage;
